import * as React from "react";

// Components
import Layout from "../../components/Layouts/Layouts";
import Seo from "../../components/Layouts/Seo";
import BannerBasic from "../../components/Content/Banner/Basic";
import LeadIn from "../../components/Content/LeadIn/Index";
import {
  AnchorHeading,
  AnchorList,
} from "../../components/Content/Anchor/Index";
import {
  AccordionList,
  AccordionItem,
} from "../../components/Content/Accordion/Index";

// assets
import iconMail from "../../assets/icons/icon-mail.svg";

// data
import faqs from "../../pages/static-pages/content/faqs.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="FAQs"
        description="Mi neque magna hac mollis nostra montes leo felis, magnis phasellus libero laoreet facilisi enim malesuada in, posuere nec lacinia dui aliquet duis sem."
      />
      <BannerBasic title="FAQs" />
      <section className="uk-section">
        <div className="uk-container">
          <div data-uk-grid="true">
            <div className="c-anchors uk-width-1-1 uk-width-1-4@s c-noprint">
              <h2 className="uk-hidden@s uk-h5 uk-text-center">
                Skip to Section
              </h2>
              <div
                id="table-contents"
                data-uk-sticky="offset: 50; bottom: true; media: @s"
              >
                <AnchorList
                  items={[
                    "Conex Bänninger",
                    ">B< Press",
                    ">B< Press XL",
                    ">B< MaxiPro",
                    ">B< Press Stainless Steel",
                    "Tools & Accessories",
                  ]}
                />
              </div>
              <hr className="uk-hidden@s" />
            </div>
            <div className="uk-width-1-1 uk-width-3-4@s">
              {faqs.section.map((item: any, index: number) => (
                <React.Fragment key={`question_${index}`}>
                  <AnchorHeading heading={item.heading} />
                  <AccordionList>
                    {item.faqs.map((item: any, index: number) => (
                      <React.Fragment key={`answer_${index}`}>
                        <AccordionItem
                          accordionTitle={item.title}
                          accordionContent={item.answer}
                        />
                      </React.Fragment>
                    ))}
                  </AccordionList>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
      <LeadIn
        section="primary"
        brandMediaUrl={iconMail}
        brandMediaTitle="Download"
        brandMediaWidth="53px"
        summary="Contact us for further information"
        paddingSize="small"
        buttonValue="Contact"
        buttonUrl="/contact"
      />
    </Layout>
  );
};

export default IndexPage;
