import * as React from "react";

interface AccordionItemProp {
  accordionTitle: any;
  accordionContent: any;
}

// markup
const AccordionList: React.FC = ({ children }) => {
  return (
    <ul data-data-uk-accordion="true" className="uk-margin-large-bottom">
      {children}
    </ul>
  );
};

// markup
const AccordionItem: React.FC<AccordionItemProp> = ({
  accordionTitle,
  accordionContent,
}) => {
  return (
    <li className="uk-margin-top uk-margin-bottom">
      <a className="uk-accordion-title" href="#">
        {accordionTitle}
      </a>
      <div
        className="uk-accordion-content uk-p@m"
        dangerouslySetInnerHTML={{
          __html: accordionContent,
        }}
      />
    </li>
  );
};

export { AccordionList, AccordionItem };
